import { __rest } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFrameThrottle, usePersistentCallback, useStateRef } from '@prophecy/utils/react/hooks';
import { noop } from 'lodash-es';
import RCTableBodyContext from 'rc-table/es/context/BodyContext';
import { useRef, useMemo, useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { createContext as createSelectableContext, useContextSelector } from 'use-context-selector';
import { Spinner } from '../Spinner';
import { theme } from '../theme';
import { VirtualizedTableWrapper } from './styled';
import { BATCH_RENDER_COUNT, TABLE_BODY, TABLE_CONTENT } from './tokens';
const ROW_OFFSET = 5;
const PAGE_RENDER_OFFSET = 0.5; // half of the visible area (extra offset on top and bottom)
const StyledSpinner = styled(Spinner) `
  background: ${theme.colors.white};
`;
const TableContext = createSelectableContext({
    initialized: false,
    observe: noop,
    scrollToIndex: noop,
    renderTable: noop,
    unobserve: noop,
    updateRowHeight: noop,
    virtualizedColumns: [],
    columnWidthMap: new Map()
});
const TableRerenderContext = createSelectableContext({
    top: 0,
    height: 0,
    rowStartIndex: 0,
    rowEndIndex: 0,
    columnStartIndex: 0,
    columnEndIndex: 0,
    bottom: 0
});
function PatchTableBodyContext({ children }) {
    const currentContext = useContext(RCTableBodyContext);
    const virtualizedColumns = useContextSelector(TableContext, (context) => context.virtualizedColumns);
    const configRef = useContextSelector(TableContext, (context) => context.configRef);
    const columnStartIndex = useContextSelector(TableRerenderContext, (context) => context.columnStartIndex);
    const columnEndIndex = useContextSelector(TableRerenderContext, (context) => context.columnEndIndex);
    const patchedContext = useMemo(() => {
        var _a;
        if (!((_a = configRef === null || configRef === void 0 ? void 0 : configRef.current) === null || _a === void 0 ? void 0 : _a.virtualizeColumns))
            return currentContext;
        const paddingColumnLeft = columnStartIndex > 0
            ? {
                key: 'column-padding-left',
                render: () => null,
                colSpan: columnStartIndex
            }
            : undefined;
        const totalColumns = configRef.current.columns.length;
        const paddingColumnRight = columnEndIndex < totalColumns - 1
            ? {
                key: 'column-padding-right',
                render: () => null,
                colSpan: totalColumns - columnEndIndex - 1
            }
            : undefined;
        return Object.assign(Object.assign({}, currentContext), { flattenColumns: [paddingColumnLeft, ...virtualizedColumns, paddingColumnRight].filter(Boolean) });
    }, [currentContext, virtualizedColumns, columnStartIndex, columnEndIndex, configRef]);
    return _jsx(RCTableBodyContext.Provider, { value: patchedContext, children: children });
}
export function extractChildRows(record, expandedKeys, rowKey) {
    if (!(record === null || record === void 0 ? void 0 : record.children) || !expandedKeys.has(record[rowKey]))
        return [];
    let childRowKeys = [];
    record.children.forEach((childRecord) => {
        const childKey = childRecord[rowKey];
        childRowKeys.push(childKey);
        // consider only expanded row's children
        if (childRecord.children) {
            childRowKeys = childRowKeys.concat(extractChildRows(childRecord, expandedKeys, rowKey));
        }
    });
    return childRowKeys;
}
export function VirtualizedTable(tableProps) {
    var _a, _b;
    const tableWrapperRef = useRef(null);
    const configRef = useContextSelector(TableContext, (context) => context.configRef);
    // change table scroll from visible to scroll based on content height
    useEffect(() => {
        if (!tableWrapperRef.current)
            return;
        const resizeObserver = new ResizeObserver((entries) => {
            var _a, _b;
            if (!tableWrapperRef.current)
                return;
            const tableScrollBody = (_a = tableWrapperRef.current) === null || _a === void 0 ? void 0 : _a.closest(`.${((_b = configRef === null || configRef === void 0 ? void 0 : configRef.current) === null || _b === void 0 ? void 0 : _b.virtualizeColumns) ? TABLE_CONTENT : TABLE_BODY}`);
            if (tableScrollBody.clientHeight < entries[0].contentRect.height) {
                tableScrollBody.style.overflowY = 'scroll';
            }
            else {
                tableScrollBody.style.overflowY = 'visible';
            }
        });
        resizeObserver.observe(tableWrapperRef.current);
    }, [configRef]);
    const height = useContextSelector(TableRerenderContext, (context) => context.height);
    const width = useContextSelector(TableRerenderContext, (context) => context.width);
    const TableElm = ((_b = (_a = configRef.current) === null || _a === void 0 ? void 0 : _a.components) === null || _b === void 0 ? void 0 : _b.table) || 'table';
    return (_jsx(VirtualizedTableWrapper, { ref: tableWrapperRef, style: { '--min-height': `${height}px`, minWidth: width }, children: _jsx(TableElm, Object.assign({}, tableProps)) }));
}
export function VirtualizedTableBody(_a) {
    var _b, _c, _d, _e;
    var { children } = _a, bodyProps = __rest(_a, ["children"]);
    const _children = children;
    // rc table renders an additional row on the top to measure row widths, we need to persist the row.
    const firstExtraRowInTable = _children[0];
    const allRows = _children[1];
    const configRef = useContextSelector(TableContext, (context) => context.configRef);
    const renderTable = useContextSelector(TableContext, (context) => context.renderTable);
    const scrollBody = useContextSelector(TableContext, (context) => context.scrollBody);
    const scrollToIndex = useContextSelector(TableContext, (context) => context.scrollToIndex);
    const rowStartIndex = useContextSelector(TableRerenderContext, (context) => context.rowStartIndex);
    const rowEndIndex = useContextSelector(TableRerenderContext, (context) => context.rowEndIndex);
    const config = configRef === null || configRef === void 0 ? void 0 : configRef.current;
    const defaultMeasureRowHeights = (_b = config === null || config === void 0 ? void 0 : config.measureRowHeights) !== null && _b !== void 0 ? _b : true;
    const hasVirtualizationControl = Boolean(config === null || config === void 0 ? void 0 : config.getVirtualizationControls);
    const isArray = Array.isArray(allRows);
    const currentRows = isArray ? allRows.slice(rowStartIndex, rowEndIndex + 1) : allRows;
    const [rowsToMeasure, setRowsToMeasure] = useState(currentRows);
    const TbodyElm = ((_e = (_d = (_c = configRef === null || configRef === void 0 ? void 0 : configRef.current) === null || _c === void 0 ? void 0 : _c.components) === null || _d === void 0 ? void 0 : _d.body) === null || _e === void 0 ? void 0 : _e.wrapper) || 'tbody';
    const [measureRowsHeight, toggleMeasuring] = useState(hasVirtualizationControl && defaultMeasureRowHeights);
    const memoizedGetVirtualizationControls = usePersistentCallback((controls) => {
        var _a;
        (_a = config === null || config === void 0 ? void 0 : config.getVirtualizationControls) === null || _a === void 0 ? void 0 : _a.call(config, controls);
    });
    useEffect(() => {
        if (!measureRowsHeight && scrollBody) {
            memoizedGetVirtualizationControls({ scrollToIndex, scrollBody });
        }
    }, [memoizedGetVirtualizationControls, scrollToIndex, scrollBody, measureRowsHeight]);
    // reset virtualization control on unmount
    useEffect(() => {
        return () => {
            memoizedGetVirtualizationControls(undefined);
        };
    }, [memoizedGetVirtualizationControls]);
    // If we have to measure row height of all rows before hand, render in batches so height can be calculated without making the browser go crazy.
    useEffect(() => {
        if (!measureRowsHeight) {
            return;
        }
        const rowsCount = allRows.length - 1;
        let batchIndex = 0;
        const renderRowsAndDelete = () => {
            if (isArray && batchIndex >= rowsCount) {
                clearInterval(renderRowsInBatchedInterval);
                renderTable(0, 0);
                toggleMeasuring(false);
            }
            if (isArray && batchIndex < rowsCount) {
                const rowsBatch = allRows.slice(batchIndex, batchIndex + BATCH_RENDER_COUNT);
                batchIndex += rowsBatch.length;
                setRowsToMeasure(rowsBatch);
            }
        };
        const renderRowsInBatchedInterval = setInterval(renderRowsAndDelete, 50);
        // avoiding running effect due to change in allRows
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const rowsToRender = measureRowsHeight ? rowsToMeasure : currentRows;
    const height = useContextSelector(TableRerenderContext, (context) => context.height);
    const top = useContextSelector(TableRerenderContext, (context) => context.top);
    const bottom = useContextSelector(TableRerenderContext, (context) => context.bottom);
    const rowBefore = _jsx("tr", { style: { height: top, visibility: 'hidden' } }, 'top-padding-row');
    const rowAfter = _jsx("tr", { style: { height: height - bottom, visibility: 'hidden' } }, 'bottom-padding-row');
    return (_jsx(PatchTableBodyContext, { children: _jsxs(TbodyElm, Object.assign({}, bodyProps, { children: [firstExtraRowInTable, [rowBefore, rowsToRender, rowAfter].filter(Boolean), measureRowsHeight ? _jsx(StyledSpinner, { size: 'xxs', spinning: true, tip: 'Loading...' }) : null] })) }));
}
export function VirtualizedRow(props) {
    var _a, _b, _c;
    const trRef = useRef(null);
    const observe = useContextSelector(TableContext, (context) => context.observe);
    const unobserve = useContextSelector(TableContext, (context) => context.unobserve);
    const initialized = useContextSelector(TableContext, (context) => context.initialized);
    const configRef = useContextSelector(TableContext, (context) => context.configRef);
    const trElm = trRef.current;
    useEffect(() => {
        if (!initialized || !trElm)
            return;
        observe(trElm);
        return () => {
            unobserve(trElm);
        };
    }, [observe, unobserve, initialized, trElm]);
    const updateRowHeight = useContextSelector(TableContext, (context) => context.updateRowHeight);
    useEffect(() => {
        if (!trElm)
            return;
        // if row height updates change the cached height.
        const observer = new ResizeObserver((entries) => {
            var _a;
            const height = (_a = entries[0]) === null || _a === void 0 ? void 0 : _a.contentRect.height;
            if (height !== undefined) {
                updateRowHeight(trElm, height);
            }
        });
        observer.observe(trElm);
        return () => {
            observer.disconnect();
        };
    }, [updateRowHeight, trElm]);
    const TRElm = ((_c = (_b = (_a = configRef === null || configRef === void 0 ? void 0 : configRef.current) === null || _a === void 0 ? void 0 : _a.components) === null || _b === void 0 ? void 0 : _b.body) === null || _c === void 0 ? void 0 : _c.row) || 'tr';
    return _jsx(TRElm, Object.assign({ ref: trRef }, props));
}
export function VirtualizeTableHeaderWrapper(_a) { var { children } = _a, props = __rest(_a, ["children"]); }
export function VirtualContainer({ children, configRef }) {
    var _a;
    const [observer, setObserver] = useState();
    const [observerStarted, setObserverStarted] = useStateRef(false);
    const { bodyHeight, rowKey, rowHeight, onScroll, columns, data, expandedKeys, containerRef, virtualizeColumns } = configRef.current;
    const currentScrollPosition = useRef({ scrollTop: 0, scrollLeft: 0 });
    const rowHeightMap = useMemo(() => new Map(), []);
    const columnWidthMap = useMemo(() => new Map(), []);
    const tableScrollBody = (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.querySelector(`.${virtualizeColumns ? TABLE_CONTENT : TABLE_BODY}`);
    const columnsWithCachedWidth = useMemo(() => {
        if (!virtualizeColumns)
            return columns;
        return columns.map((column) => {
            const { key, width } = column;
            // assert for key and width, if key is not present throw error, if width is not present or not in format of number or pixel throw error
            if (!key || !width || (typeof width === 'string' && !width.endsWith('px'))) {
                throw new Error('Column key and fixed width for every column is required for column virtualization to work');
            }
            // if column width is not set on the cache, cache it or else return the cached width
            if (!columnWidthMap.has(key.toString())) {
                columnWidthMap.set(key.toString(), typeof width === 'string' ? parseFloat(width) : width);
            }
            return Object.assign(Object.assign({}, column), { width: columnWidthMap.get(key.toString()) });
        });
    }, [columns, virtualizeColumns, columnWidthMap]);
    const [vtBoundaries, setBoundaries] = useState({
        top: 0,
        height: bodyHeight,
        rowStartIndex: 0,
        rowEndIndex: Math.ceil(bodyHeight / rowHeight) + ROW_OFFSET,
        columnStartIndex: 0,
        columnEndIndex: columns.length - 1
    });
    const hasData = !!data.length;
    const _expandedKeys = useMemo(() => new Set(expandedKeys), [expandedKeys]);
    const getRowHeight = (rowKey) => {
        return rowHeightMap.get(rowKey === null || rowKey === void 0 ? void 0 : rowKey.toString()) || rowHeight;
    };
    const getColumnWidth = (columnKey) => {
        return columnWidthMap.get(columnKey);
    };
    const getDataRowKeys = () => {
        const rowKeys = [];
        data.forEach((item) => {
            rowKeys.push(item[rowKey]);
            const childRowKeys = extractChildRows(item, _expandedKeys, rowKey);
            rowKeys.push(...childRowKeys);
        });
        return rowKeys;
    };
    const getTableOffset = (scrollTop, scrollLeft) => {
        let rowStartIndex, rowEndIndex;
        // flatten all expanded rows as rc-table does, so our index logic is correct
        const rowKeys = getDataRowKeys();
        // Working as pages, PAGE_RENDER_OFFSET page above the visible rows and PAGE_RENDER_OFFSET page below
        const top = Math.max(0, scrollTop - bodyHeight * PAGE_RENDER_OFFSET);
        const bottom = scrollTop + bodyHeight + bodyHeight * PAGE_RENDER_OFFSET;
        // given current scrollTop find what all rows should be render, find the rendering boundary
        let itemPos = 0, topOffset, bottomOffset;
        for (let i = 0, rowsLn = rowKeys.length; i < rowsLn; i++) {
            const key = rowKeys[i];
            const rowHeight = getRowHeight(key);
            if (rowStartIndex === undefined) {
                if (itemPos > top || itemPos + rowHeight > top) {
                    topOffset = itemPos;
                    rowStartIndex = i;
                }
            }
            else if (itemPos < bottom) {
                rowEndIndex = i;
            }
            else if (bottomOffset === undefined) {
                bottomOffset = itemPos;
            }
            itemPos += rowHeight;
        }
        if (!bottomOffset)
            bottomOffset = itemPos;
        const height = itemPos;
        let width = undefined;
        let columnStartIndex;
        let columnEndIndex;
        if (virtualizeColumns && tableScrollBody) {
            const tableBodyWidth = tableScrollBody.clientWidth;
            const left = Math.max(0, scrollLeft - tableBodyWidth * PAGE_RENDER_OFFSET);
            const right = scrollLeft + tableBodyWidth + tableBodyWidth * PAGE_RENDER_OFFSET;
            // given current scrollLeft find what all columns should be render, find the rendering boundary
            let columnPos = 0;
            for (let i = 0, columnsLn = columnsWithCachedWidth.length; i < columnsLn; i++) {
                const column = columnsWithCachedWidth[i];
                const columnKey = column.key.toString();
                const columnWidth = getColumnWidth(columnKey);
                if (columnStartIndex === undefined) {
                    if (columnPos > left || columnPos + columnWidth > left) {
                        columnStartIndex = i;
                    }
                }
                else if (columnPos < right) {
                    columnEndIndex = i;
                }
                columnPos += columnWidth;
            }
            width = columnPos;
        }
        return {
            height,
            width,
            topOffset,
            bottomOffset,
            rowStartIndex,
            rowEndIndex,
            columnStartIndex: columnStartIndex || 0,
            columnEndIndex: columnEndIndex || columnsWithCachedWidth.length - 1,
            rowKeys
        };
    };
    const rerenderTable = usePersistentCallback((scrollTop, scrollLeft) => {
        if (!bodyHeight)
            return;
        currentScrollPosition.current = { scrollTop, scrollLeft };
        const { topOffset, bottomOffset, rowStartIndex, rowEndIndex, columnStartIndex, columnEndIndex, rowKeys, height, width } = getTableOffset(scrollTop, scrollLeft);
        const lastIndex = rowEndIndex === undefined ? rowKeys.length - 1 : rowEndIndex;
        setBoundaries({
            top: topOffset,
            bottom: bottomOffset,
            rowStartIndex: rowStartIndex === undefined ? 0 : rowStartIndex,
            rowEndIndex: lastIndex,
            columnStartIndex: columnStartIndex,
            columnEndIndex: columnEndIndex,
            height,
            width
        });
    });
    const scrollToIndex = usePersistentCallback((index) => {
        if (!bodyHeight)
            return;
        const rowKeys = getDataRowKeys();
        let scrollTop = 0;
        for (let i = 0; i <= index; i++) {
            scrollTop += getRowHeight(rowKeys[i]);
        }
        if (tableScrollBody) {
            tableScrollBody.scrollTo(0, scrollTop > bodyHeight ? scrollTop - bodyHeight : scrollTop - getRowHeight(rowKeys[index]));
        }
    });
    const observerStartedValue = observerStarted.current;
    useEffect(() => {
        if (observerStartedValue) {
            // render the table first time
            rerenderTable(0, 0);
        }
    }, [observerStartedValue, rerenderTable]);
    useEffect(() => {
        if (!hasData || !tableScrollBody)
            return;
        /**
         * Instead of trying to extract height of a element using .offsetHeight (which causes a reflow)
         * We use an intersection observer boundingClientRect.
         * We want the height of an element after they are rendered,and before they are unmounted
         * (as some height change can happen when user is interacting on it while its rendered)
         * Intersection observer serves the purpose.
         */
        const _observer = new IntersectionObserver((entries) => {
            if (!observerStarted.current) {
                setObserverStarted(true);
            }
            entries.forEach((entry) => {
                // store the height of the row
                const recordKey = entry.target.dataset.rowKey;
                if (recordKey !== undefined) {
                    rowHeightMap.set(recordKey, entry.boundingClientRect.height);
                }
            });
        }, 
        // the root margin could be anything just keeping it high so element height are marked as soon as they are rendered
        { root: tableScrollBody, threshold: 0, rootMargin: '1000px' });
        setObserver(_observer);
        return () => _observer === null || _observer === void 0 ? void 0 : _observer.disconnect();
    }, [rowHeightMap, hasData, setObserverStarted, observerStarted, tableScrollBody]);
    const updateRowHeight = usePersistentCallback((rowElm, height) => {
        const recordKey = rowElm.dataset.rowKey;
        if (recordKey !== undefined) {
            rowHeightMap.set(recordKey, height);
        }
    });
    const blockPosition = useRef({ top: 0, left: 0 });
    const throttleRaf = useFrameThrottle((tableScrollBody) => {
        const { scrollTop, scrollLeft, clientWidth } = tableScrollBody;
        setTimeout(() => {
            currentScrollPosition.current = { scrollTop, scrollLeft };
            /**
             * handle virtualization in blocks, the following strategy is used
             * - Over scan rows and columns based on page size, for page size we take half of the visible area
             * - Recompute what needs to rendered in blocks, the block size can be half of the page size, so we don't
             *   endup recomputing what rows and columns to render too frequently
             */
            const newBlockPosition = {
                top: Math.floor(scrollTop / (bodyHeight * PAGE_RENDER_OFFSET * 0.5)),
                left: Math.floor(scrollLeft / (clientWidth * PAGE_RENDER_OFFSET * 0.5))
            };
            if (newBlockPosition.top !== blockPosition.current.top || newBlockPosition.left !== blockPosition.current.left) {
                blockPosition.current = newBlockPosition;
                rerenderTable(scrollTop, scrollLeft);
            }
        });
        onScroll === null || onScroll === void 0 ? void 0 : onScroll(scrollTop, tableScrollBody);
    }, true);
    useEffect(() => {
        if (!hasData || !tableScrollBody)
            return;
        // listen on scroll event of table body
        const onBodyScroll = (e) => {
            throttleRaf(tableScrollBody);
        };
        tableScrollBody.addEventListener('scroll', onBodyScroll);
        return () => tableScrollBody.removeEventListener('scroll', onBodyScroll);
    }, [rerenderTable, throttleRaf, hasData, tableScrollBody]);
    useEffect(() => {
        const { scrollTop, scrollLeft } = currentScrollPosition.current;
        // on scroll height update or change in data (delete or add), or expand/unexpand rerender table
        rerenderTable(scrollTop, scrollLeft);
    }, [rerenderTable, bodyHeight, data.length, [...expandedKeys].join()]); // eslint-disable-line react-hooks/exhaustive-deps
    // on update of columns, rerender table to start position horizontally
    useEffect(() => {
        const { scrollTop } = currentScrollPosition.current;
        rerenderTable(scrollTop, 0);
    }, [columns.length]);
    const columnsToShow = useMemo(() => {
        return columnsWithCachedWidth.slice(vtBoundaries.columnStartIndex, vtBoundaries.columnEndIndex + 1);
    }, [columnsWithCachedWidth, vtBoundaries.columnStartIndex, vtBoundaries.columnEndIndex]);
    const tableContext = useMemo(() => {
        return {
            renderTable: rerenderTable,
            updateRowHeight,
            scrollToIndex,
            initialized: !!observer,
            configRef,
            scrollBody: tableScrollBody,
            virtualizedColumns: columnsToShow,
            columnWidthMap,
            observe: (elm) => {
                observer === null || observer === void 0 ? void 0 : observer.observe(elm);
            },
            unobserve: (elm) => {
                observer === null || observer === void 0 ? void 0 : observer.unobserve(elm);
            }
        };
    }, [
        observer,
        scrollToIndex,
        updateRowHeight,
        rerenderTable,
        configRef,
        tableScrollBody,
        columnsToShow,
        columnWidthMap
    ]);
    return (_jsx(TableContext.Provider, { value: tableContext, children: _jsx(TableRerenderContext.Provider, { value: vtBoundaries, children: children }) }));
}
export function useVirtualization(config) {
    // passing virtualization config as ref so that we don't end up creating new Table Component every time config changes
    // Note the inline table function, can create different reference breaking reconciliation
    const configRef = useRef(config);
    configRef.current = config;
    const tableComponents = useMemo(() => {
        var _a, _b, _c;
        const tableComponents = {
            table: VirtualizedTable,
            header: (_a = configRef.current.components) === null || _a === void 0 ? void 0 : _a.header,
            body: {
                wrapper: VirtualizedTableBody,
                row: VirtualizedRow,
                cell: (_c = (_b = configRef.current.components) === null || _b === void 0 ? void 0 : _b.body) === null || _c === void 0 ? void 0 : _c.cell
            }
        };
        function _VirtualContainer(props) {
            if (!configRef.current.virtualize) {
                return props.children;
            }
            return _jsx(VirtualContainer, { configRef: configRef, children: props.children });
        }
        return {
            VirtualContainer: _VirtualContainer,
            virtualizedComponents: tableComponents
        };
    }, []);
    return tableComponents;
}
export function useVirtualizedTableContext(selector = (context) => context) {
    return useContextSelector(TableContext, selector);
}
